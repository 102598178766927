.cardContainer {
  position: absolute;
  width: 300px;
  height: 300px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardRotator {
  position: absolute;
  touch-action: none;
  background-color: midnightblue;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  width: 300px;
  height: 300px;
  will-change: transform;
  border-radius: 10px;
  border: 5px solid black;
  /* box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3); */
}

.cardContents {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardFront {
  overflow: clip;

}

.cardBack {

}
